import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { Injectable, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import localeJa from '@angular/common/locales/ja';
import { registerLocaleData, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

registerLocaleData(localeJa, 'ja');

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  public overrides = {
	  swipe: { direction: Hammer.DIRECTION_ALL },
	  pan: { threshold: 5 }
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
		BrowserModule,
		AppRoutingModule,
		IonicModule.forRoot(),
		CommonModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		HammerModule,
		CalendarModule.forRoot({
		  provide: DateAdapter,
		  useFactory: adapterFactory,
		}),
	],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig, }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
